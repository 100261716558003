import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home";
import Services from "./Pages/Services/Services";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import FloatingBtn from "./Components/floating-button/FloatingBtn";
import ReassembleAndassemle from "./Pages/moreAboutServices/reassembleAndassemle/ReassembleAndassemle";
import Storage from "./Pages/moreAboutServices/storage/Storage";
import Transfer from "./Pages/moreAboutServices/transfer/Transfer";
import Wrapping from "./Pages/moreAboutServices/wrapping/Wrapping";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoading = () => {
      setLoading(false);
    }

     if (document.readyState === "complete") {
       handleLoading();
     } else {
       window.addEventListener("load", handleLoading);
     }
    
     return () => {
       window.removeEventListener("load", handleLoading);
     };
  }, [])
  return (
    <div className="App">
      {loading ? (
        <div className="loading-container absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
          <svg viewBox="0 0 240 240" height="240" width="240" className="pl">
            <circle
              stroke-linecap="round"
              stroke-dashoffset="-330"
              stroke-dasharray="0 660"
              stroke-width="20"
              stroke="#000"
              fill="none"
              r="105"
              cy="120"
              cx="120"
              className="pl__ring pl__ring--a"></circle>
            <circle
              stroke-linecap="round"
              stroke-dashoffset="-110"
              stroke-dasharray="0 220"
              stroke-width="20"
              stroke="#000"
              fill="none"
              r="35"
              cy="120"
              cx="120"
              className="pl__ring pl__ring--b"></circle>
            <circle
              stroke-linecap="round"
              stroke-dasharray="0 440"
              stroke-width="20"
              stroke="#000"
              fill="none"
              r="70"
              cy="120"
              cx="85"
              className="pl__ring pl__ring--c"></circle>
            <circle
              stroke-linecap="round"
              stroke-dasharray="0 440"
              stroke-width="20"
              stroke="#000"
              fill="none"
              r="70"
              cy="120"
              cx="155"
              className="pl__ring pl__ring--d"></circle>
          </svg>
          <p>...Loading</p>
        </div>
      ) : (
        <>
          <Header />

          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/aboutUs" element={<AboutUs />}></Route>
            <Route path="/contactUs" element={<ContactUs />}></Route>
            <Route path="/storage" element={<Storage />}></Route>
            <Route path="/transfer" element={<Transfer />}></Route>
            <Route path="/wrapping" element={<Wrapping />}></Route>
            <Route
              path="/reassembleAndassemle"
              element={<ReassembleAndassemle />}></Route>
          </Routes>

          <FloatingBtn />

          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
